import { AuditLog } from "../../models/AuditLog";
import React, { useState } from "react";
import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";


export const EventTableRow = (props: {
  auditLog: AuditLog,
  matchesLgDown: boolean,
  matchesMdDown: boolean,
  projectType: "hanko_project" | "passkey_project"
}) => {
  const [open, setOpen] = useState(false)
  const {auditLog, matchesLgDown, matchesMdDown, projectType} = props

  const occurredAt = new Intl.DateTimeFormat('default', {
    dateStyle: "medium",
    timeStyle: "medium"
  }).format(new Date(auditLog.created_at))

  return <>
    <TableRow hover key={auditLog.id} onClick={() => setOpen(!open)} sx={{cursor: 'pointer'}}>
      <TableCell sx={{borderBottom: 'unset'}}>{auditLog.type}</TableCell>
      <TableCell sx={{
        display: matchesLgDown || (projectType === "passkey_project") ? 'none' : 'table-cell',
        borderBottom: 'unset'
      }} hidden={matchesLgDown}>{auditLog.actor_email}</TableCell>
      <TableCell sx={{
        display: matchesLgDown || (projectType === "hanko_project") ? 'none' : 'table-cell',
        borderBottom: 'unset'
      }} hidden={matchesLgDown}>{auditLog.actor_user_id}</TableCell>
      <TableCell sx={{display: matchesLgDown ? 'none' : 'table-cell', borderBottom: 'unset'}}
                 hidden={matchesLgDown}>{auditLog.meta_source_ip}</TableCell>
      <TableCell sx={{display: matchesMdDown ? 'none' : 'table-cell', borderBottom: 'unset'}}
                 hidden={matchesMdDown}>{occurredAt}</TableCell>
      <TableCell sx={{borderBottom: 'unset'}}>
        <IconButton aria-label="expand row"
                    size="small"
                    disableRipple
        >
          {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
        </IconButton>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={{pb: 0, pt: 0}} colSpan={5}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{margin: 2}}>
            {auditLog.error !== undefined && auditLog.error !== null && auditLog.error !== "" ?
              <EventDetail title="Error" content={auditLog.error}/> : null}
            <EventDetail title="Http RequestId" content={auditLog.meta_http_request_id}/>
            {matchesLgDown ? <EventDetail title="IP" content={auditLog.meta_source_ip}/> : null}
            <EventDetail title="User Agent" content={auditLog.meta_user_agent}/>
            {((auditLog.actor_user_id !== undefined && auditLog.actor_user_id !== null && auditLog.actor_user_id !== "" && projectType === "hanko_project") || (projectType === "passkey_project" && matchesLgDown && auditLog.actor_user_id !== undefined)) ?
              <EventDetail title="User ID" content={auditLog.actor_user_id}/> : null}
            {matchesLgDown && auditLog.actor_email !== undefined ?
              <EventDetail title="Email" content={auditLog.actor_email}/> : null}
            {matchesMdDown ? <EventDetail title="Occurred at (UTC)" content={occurredAt}/> : null}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
}

const EventDetail = (props: { title: string, content: string }) => {
  return <Box sx={{mt: 0.5, mb: 0.5}}>
    <Typography variant="body2" sx={{fontWeight: 'bold'}}>{props.title}</Typography>
    <Typography variant="body2">{props.content}</Typography>
  </Box>
}
