import { Await, createSearchParams, useLoaderData, useNavigate, useOutletContext } from "react-router-dom";
import { Box, Button, CircularProgress, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Email, ProjectUser } from "../../../models/ProjectUser";
import Grid2 from "@mui/material/Unstable_Grid2";
import { CheckCircle, ChevronLeft } from "@mui/icons-material";
import { ContextType } from "../ProjectWrapper";
import { ApiClient } from "../../../client/ApiClient";
import { EventsTable } from "../../../components/EventsTable/EventsTable";
import { AuditLogsWithTotalCount } from "../../../models/AuditLog";
import { Organization } from "../../../models/Organization";
import { Project } from "../../../models/Project";
import { HankoTooltip } from "../../../components/Tooltip/Tooltip";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { WebAuthnCredentialTable } from '../../../components/User/WebAuthnCredentialTable'
import { SnackbarContext } from "../../../components/Snackbar/SnackbarProvider";
import { Width } from "../../../const/Width";
import { SuspendedInfoComponent } from "../../../components/SuspendedInfoComponent";

export const UserDetail = () => {
  const data = useLoaderData() as any
  const navigate = useNavigate()
  const {project, organization} = useOutletContext() as ContextType
  const [tabIndex, setTabIndex] = useState(0)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const {error} = useContext(SnackbarContext)

  const navigateBack = () => {
    navigate(-1)
  }

  const deleteUser = async (userId: string) => {
    try {
      await ApiClient.deleteProjectUser(organization.id, project.id, userId)
      navigateBack()
    } catch (e) {
      error("failed to delete user")
    }
  }

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const dateFormat = new Intl.DateTimeFormat('default', {
    dateStyle: "medium",
    timeStyle: "medium"
  })

  return <Box maxWidth={Width.WIDE}>
    {project.status.replicas === 0 ?
      <SuspendedInfoComponent project={project} organization={organization} type="data" dataType="user"/> :
      <React.Suspense fallback={<CircularProgress/>}>
        <Await resolve={data.user} errorElement={<div>Failed to load user details</div>}>
          {(user: Awaited<ProjectUser>) => (
            <Box>
              <Grid2 container rowSpacing={5} columnSpacing={3}>
                <Grid2>
                  <Button variant="outlined" startIcon={<ChevronLeft/>} onClick={navigateBack}>Back</Button>
                </Grid2>
                <Grid2 xs={12}>
                  <Stack gap={2.5}>
                    {user.emails?.sort((a, b) => Number(b.is_primary) - Number(a.is_primary))?.map((email) => {
                      return <EmailView key={email.id} email={email}/>
                    })}
                  </Stack>
                </Grid2>
                <Grid2 xs={12} md={3}>
                  <Typography variant="body2" fontWeight="bold">Created at (UTC)</Typography>
                  <Typography variant="body2">{dateFormat.format(new Date(user.created_at))}</Typography>
                </Grid2>
                <Grid2 xs={12} md={6}>
                  <Typography variant="body2" fontWeight="bold">User ID</Typography>
                  <Typography variant="body2">{user.id}</Typography>
                </Grid2>
                <Grid2 xs={12} md={3}>
                  <Button variant="outlined" color="error" onClick={() => {
                    setDeleteModalOpen(true)
                  }}>Delete</Button>
                  <ConfirmationModal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)} onConfirm={() => {
                    deleteUser(user.id)
                  }} title="Delete user" description={`Are you sure you want to delete user '${user.id}'?`}
                                     buttonText="Delete user"/>
                </Grid2>
                <Grid2 xs={12}>
                  <Box sx={{borderBottom: 1, borderColor: 'divider', width: '100%'}}>
                    <Tabs value={tabIndex} onChange={onTabChange}>
                      <Tab label="Passkeys" sx={{fontSize: '1rem'}}/>
                      <Tab label="Events" sx={{fontSize: '1rem'}}/>
                    </Tabs>
                  </Box>
                  <TabPanel value={tabIndex} index={0}>
                    <WebAuthnCredentialTable webauthCredentials={user.webauthn_credentials ?? []}/>
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <UserDetailEventsTable organization={organization} project={project} userId={user.id}/>
                  </TabPanel>
                </Grid2>
              </Grid2>
            </Box>
          )}
        </Await>
      </React.Suspense>
    }
  </Box>
}

const EmailView = (props: { email: Email }) => {
  const {email} = props
  const variant = email.is_primary ? "h6" : "body1"
  const fontWeight = email.is_primary ? 600 : 400
  return <Stack direction="row" gap={2} alignItems="center">
    <Typography variant={variant} fontWeight={fontWeight}>{email.address}</Typography>
    {email.is_verified ? <HankoTooltip title="Verified" placement="right"><CheckCircle fontSize="small"
                                                                                       color="success"/></HankoTooltip> : null}
  </Stack>
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const {value, index, children} = props
  return <Box hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}>
    {value === index && (
      <Box sx={{py: 3}}>
        {children}
      </Box>
    )}
  </Box>
}

const UserDetailEventsTable = (props: { organization: Organization, project: Project, userId: string }) => {
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [page, setPage] = useState(0)
  const [loader, setLoader] = useState<undefined | Promise<AuditLogsWithTotalCount>>()
  const {organization, project, userId} = props

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page)
  }

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const rowsPerPage = parseInt(event.target.value, 10)
    setPage(0)
    setRowsPerPage(rowsPerPage)
  }

  useEffect(() => {
    const queryParams = createSearchParams({
      'page': (page + 1).toString(),
      'per_page': rowsPerPage.toString(10),
      'user_id': userId
    })
    setLoader(ApiClient.getAuditLogs(organization.id, project.id, queryParams))
  }, [page, rowsPerPage, organization, project, userId])

  return <EventsTable loader={loader} rowsPerPage={rowsPerPage} page={page} onRowsPerPageChange={onRowsPerPageChange}
                      onPageChange={onPageChange} noResultsFoundThroughSearch={false} projectType="hanko_project"/>
}