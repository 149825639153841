import { generatePath } from "react-router-dom";

const API_HOST = `${window.REACT_APP_API_HOST}`
export const API_URL = `/api`

export const GET_CURRENT_USER = `${API_URL}/users/me`

export const CREATE_ORGANIZATION = `${API_URL}/organizations/`
export const GET_ORGANIZATION = `${API_URL}/organizations/:organizationId`
export const UPDATE_ORGANIZATION = `${API_URL}/organizations/:organizationId`
export const DELETE_ORGANIZATION = `${API_URL}/organizations/:organizationId`
export const GET_SUBSCRIPTION = `${API_URL}/organizations/:organizationId/subscription`

export const GET_ORGANIZATION_USERS = `${API_URL}/organizations/:organizationId/users`
export const DELETE_ORGANIZATION_USERS = `${API_URL}/organizations/:organizationId/users/:userId`
export const INVITE_ORGANIZATION_USERS = `${API_URL}/organizations/:organizationId/users`
export const REVOKE_ORGANIZATION_USERS_INVITATION = `${API_URL}/organizations/:organizationId/invitations/:invitationId`
export const LIST_INVITATIONS = `${API_URL}/organizations/:organizationId/invitations`

export const GET_INVITATION = `${API_URL}/invitations/:token`
export const ACCEPT_INVITATION = `${API_URL}/invitations/:token`
export const DECLINE_INVITATION = `${API_URL}/invitations/:token`

export const GET_PROJECTS = `${API_URL}/organizations/:organizationId/projects/`
export const CREATE_PROJECTS = `${API_URL}/organizations/:organizationId/projects/`
export const GET_PROJECT = `${API_URL}/organizations/:organizationId/projects/:projectId`
export const UPDATE_PROJECT = `${API_URL}/organizations/:organizationId/projects/:projectId`
export const DELETE_PROJECT = `${API_URL}/organizations/:organizationId/projects/:projectId`
export const GET_PROJECT_STATUS = `${API_URL}/organizations/:organizationId/projects/:projectId/status`
export const REACTIVATE_PROJECT = `${API_URL}/organizations/:organizationId/projects/:projectId/reactivate`

export const GET_CHECKOUT_SESSION = `${API_URL}/organizations/:organizationId/subscribe`
export const GET_CUSTOMER_PORTAL_SESSION = `${API_URL}/organizations/:organizationId/customer_portal`

export const GET_PROJECT_USERS = `${API_URL}/organizations/:organizationId/projects/:projectId/users`
export const CREATE_PROJECT_USERS = `${API_URL}/organizations/:organizationId/projects/:projectId/users`
export const GET_PROJECT_USER = `${API_URL}/organizations/:organizationId/projects/:projectId/users/:userId`
export const DELETE_PROJECT_USER = `${API_URL}/organizations/:organizationId/projects/:projectId/users/:userId`

export const GET_PROJECT_AUDIT_LOGS = `${API_URL}/organizations/:organizationId/projects/:projectId/audit_logs`
export const GET_PROJECT_MONTHLY_ACTIVE_USER = `${API_URL}/organizations/:organizationId/projects/:projectId/monthly_active_user`
export const GET_PROJECT_MONTHLY_ACTIVE_USER_LIST = `${API_URL}/organizations/:organizationId/projects/:projectId/monthly_active_user/list`
export const GET_PROJECT_DAILY_ACTIVE_USER = `${API_URL}/organizations/:organizationId/projects/:projectId/daily_active_user`

export const GET_PROJECT_DAILY_TOTAL_USERS = `${API_URL}/organizations/:organizationId/projects/:projectId/total_user/daily`
export const GET_PROJECT_MONTHLY_TOTAL_USERS = `${API_URL}/organizations/:organizationId/projects/:projectId/total_user/monthly`
export const GET_PROJECT_LAST_TOTAL_USERS = `${API_URL}/organizations/:organizationId/projects/:projectId/total_user/last`

export const GET_PROJECT_DAILY_SIGNUP_STATS = `${API_URL}/organizations/:organizationId/projects/:projectId/sign_ups/daily`
export const GET_PROJECT_MONTHLY_SIGNUP_STATS = `${API_URL}/organizations/:organizationId/projects/:projectId/sign_ups/monthly`

export const CREATE_API_KEY = `${API_URL}/organizations/:organizationId/projects/:projectId/api_keys`
export const DELETE_API_KEY = `${API_URL}/organizations/:organizationId/projects/:projectId/api_keys/:apiKeyId`

export const UPLOAD_USER_IMPORT_FILE = `${API_URL}/organizations/:organizationId/projects/:projectId/user_import`
export const LIST_USER_IMPORT_JOBS = `${API_URL}/organizations/:organizationId/projects/:projectId/user_import`
export const GET_USER_IMPORT_LOGS = `${API_URL}/organizations/:organizationId/projects/:projectId/user_import/:jobId/logs`

export const SIGN_OUT = `${API_URL}/sign_out`

export const CREATE_SAML_PROVIDER = `${API_URL}/organizations/:organizationId/projects/:projectId/saml/provider`
export const UPDATE_SAML_PROVIDER = `${API_URL}/organizations/:organizationId/projects/:projectId/saml/provider/:providerId`
export const DELETE_SAML_PROVIDER = `${API_URL}/organizations/:organizationId/projects/:projectId/saml/provider/:providerId`

export const WEBHOOKS = `${API_URL}/organizations/:organizationId/projects/:projectId/webhooks`

// Urls for Passkey projects
export const CREATE_PASSKEY_PROJECT = `${API_URL}/organizations/:organizationId/passkey_projects/`
export const LIST_PASSKEY_PROJECT = `${API_URL}/organizations/:organizationId/passkey_projects/`
export const GET_PASSKEY_PROJECT = `${API_URL}/organizations/:organizationId/passkey_projects/:projectId`
export const UPDATE_PASSKEY_PROJECT = `${API_URL}/organizations/:organizationId/passkey_projects/:projectId`
export const DELETE_PASSKEY_PROJECT = `${API_URL}/organizations/:organizationId/passkey_projects/:projectId`
export const CREATE_PASSKEY_PROJECT_API_KEY = `${API_URL}/organizations/:organizationId/passkey_projects/:projectId/api_keys`
export const LIST_PASSKEY_PROJECT_API_KEY = `${API_URL}/organizations/:organizationId/passkey_projects/:projectId/api_keys`
export const DELETE_PASSKEY_PROJECT_API_KEY = `${API_URL}/organizations/:organizationId/passkey_projects/:projectId/api_keys/:apiKeyId`
export const LIST_PASSKEY_PROJECT_AUDIT_LOGS = `${API_URL}/organizations/:organizationId/passkey_projects/:projectId/audit_logs`

export const GET_PASSKEY_PROJECT_MONTHLY_ACTIVE_USER_LIST = `${API_URL}/organizations/:organizationId/passkey_projects/:projectId/monthly_active_user/list`
export const GET_PASSKEY_PROJECT_DAILY_ACTIVE_USER = `${API_URL}/organizations/:organizationId/passkey_projects/:projectId/daily_active_user`


export const generateApiUrl = <Path extends string>(path: string, params: {
  [key in PathParam<Path>]: string;
} = {} as any) => {
  return `${API_HOST}${generatePath(path, params)}`
}

type PathParam<Path extends string> =
// check if path is just a wildcard
  Path extends "*"
    ? "*"
    : // look for wildcard at the end of the path
    Path extends `${infer Rest}/*`
      ? "*" | _PathParam<Rest>
      : // look for params in the absence of wildcards
      _PathParam<Path>;

// Recursive helper for finding path parameters in the absence of wildcards
type _PathParam<Path extends string> =
// split path into individual path segments
  Path extends `${infer L}/${infer R}`
    ? _PathParam<L> | _PathParam<R>
    : // find params after `:`
    Path extends `${string}:${infer Param}`
      ? Param
      : // otherwise, there aren't any params present
      never;