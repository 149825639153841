import React, { useState } from 'react'
import {
  Box,
  Drawer,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavbarItems } from "./navbarItems";
import { OpenInNew } from "@mui/icons-material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as Logo } from '../../assets/hanko-logo.svg';
import { Organization } from "../../models/Organization";
import { NavbarItem } from "./NavbarItem";

interface Props {
  text?: () => String;
  navbarItems: NavbarItems[]
  organizations: Organization[]
  activeOrganization?: Organization
  mobileOpen: boolean
  backButton: boolean
  onMobileClose: () => void
}

const Navbar = (props: Props) => {
  const navigate = useNavigate();
  const drawerWidth = 300;
  const [activeOrganization, setActiveOrganization] = React.useState(props.activeOrganization ? props.activeOrganization.id : '');
  const theme = useTheme()
  const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const handleOrganisationChange = (event: SelectChangeEvent) => {
    if (!document.location.pathname.startsWith(`/organizations/${event.target.value}`)) {
      setActiveOrganization(event.target.value as string)
      document.location.href = `/organizations/${event.target.value}/projects`
    }
  }

  const handleAllProjectsClick = () => {
    navigate(`/organizations/${activeOrganization}/projects`)
  }

  const drawer = (
    <Stack
      sx={{
        height: '100vh',
        overflow: 'auto',
        borderRadius: '0 16px 16px 0',
        display: 'flex',
      }}
    >
      <Toolbar variant="large" sx={{px: {xs: 2}}}>
        <Link href="/organizations" display="flex" alignItems="center">
          <Logo title="Hanko Logo" height="50px"/>
        </Link>
      </Toolbar>
      {props.organizations.length != 0 ? <Box width="100%" padding="5px">
        <Select
          fullWidth
          id="organization-select"
          value={activeOrganization}
          onChange={handleOrganisationChange}
        >
          {
            props.organizations.map((organization) => {
              return <MenuItem value={organization.id} key={organization.id}>{organization.name}</MenuItem>
            })
          }
        </Select>
      </Box> : null}

      <List>
        {props.backButton ?
          <ListItem divider disablePadding sx={{
            mt: 2.5,
            mb: 4,
            borderTop: '1px solid rgba(255, 255, 255, 0.12)',
          }}>
            <ListItemButton onClick={handleAllProjectsClick}>
              <ListItemIcon>
                <ArrowBackIosIcon/>
              </ListItemIcon>
              <ListItemText primary="All projects"/>
            </ListItemButton>
          </ListItem> : <></>
        }
        {
          props.navbarItems.map((item, index) => (
            <NavbarItem key={item.id} item={item} onNavigate={props.onMobileClose}/>
          ))
        }
      </List>


      <List sx={{marginTop: "auto"}}>
        <ListItem>
          <ListItemButton href="https://docs.hanko.io/" target="_blank">
            <ListItemText primary="Documentation"/>
            <OpenInNew/>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton href="https://discord.gg/tAG6RrRqJD" target="_blank">
            <ListItemText primary="Discord"/>
            <OpenInNew/>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton href="https://github.com/teamhanko/hanko" target="_blank">
            <ListItemText primary="Github"/>
            <OpenInNew/>
          </ListItemButton>
        </ListItem>

      </List>
    </Stack>
  );

  return (
    <Box>
      {matchesSmDown ? <Drawer
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.onMobileClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        PaperProps={{
          sx: {
            backgroundImage: "none"
          }
        }}
        sx={{
          display: {xs: 'block', sm: 'none'},
          '& .MuiDrawer-paper':
            {
              boxSizing: 'border-box',
              width: `min(100vw, ${drawerWidth}px)`,
              borderRadius: '0 16px 16px 0',
            },
        }}
      >
        {drawer}
      </Drawer> : <Drawer
        variant="permanent"
        sx={{
          display: {xs: 'none', sm: 'block'},
          '& .MuiDrawer-paper':
            {
              boxSizing: 'border-box',
              width: drawerWidth,
              borderRadius: '0 16px 16px 0',
            },
        }}
        open
      >
        {drawer}
      </Drawer>}

    </Box>
  )
}

export default Navbar