import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { ApiClient } from "../../../client/ApiClient";
import { Await, createSearchParams, useNavigate, useOutletContext, useSearchParams } from "react-router-dom";
import { ContextType } from "../ProjectWrapper";
import { ProjectUsersWithTotalCount } from "../../../models/ProjectUser";
import { ChevronRight, PersonAdd, Search, Upload } from "@mui/icons-material";
import { LoadingTable } from "../../../components/LoadingTable";
import { Width } from "../../../const/Width";
import { SuspendedInfoComponent } from "../../../components/SuspendedInfoComponent";
import { ErrorTable } from "../../../components/ErrorTable";
import { CreateUserModal } from "./CreateUserModal";


const User = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {project, organization} = useOutletContext() as ContextType
  const [loader, setLoader] = useState<undefined | Promise<ProjectUsersWithTotalCount>>()
  const [isCreateUserModalOpen, setCreateUserModalOpen] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()
  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'))
  const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const sp = new URLSearchParams(searchParams)
    sp.set("page", page.toString(10))
    setSearchParams(sp)
  }

  const onRowsPerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const sp = new URLSearchParams(searchParams)
    sp.set("per_page", event.target.value)
    sp.set("page", "0")
    setSearchParams(sp)
  }

  const search = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      search: { value: string }
    }

    const trimmed = target.search.value.trim()
    const sp = new URLSearchParams(searchParams)
    sp.set("q", trimmed)
    sp.set("page", "0")
    setSearchParams(sp)
  }

  useEffect(() => {
    loadUsers()
  }, [organization, project, searchParams])

  const loadUsers = () => {
    const page = parseInt(searchParams.get("page") ?? "0") + 1
    const queryParams = createSearchParams({
      'page': page.toString(10),
      'per_page': searchParams.get("per_page") ?? "25",
      "q": searchParams.get("q") ?? ""
    })
    setLoader(ApiClient.getProjectUsers(organization.id, project.id, queryParams))
  }

  const onCloseCreateUserModal = (success?: boolean) => {
    if (success === true) {
      loadUsers()
    }

    setCreateUserModalOpen(false)
  }

  let columnCount = 4
  if (matchesLgDown) {
    columnCount -= 1
  }
  if (matchesMdDown) {
    columnCount -= 1
  }

  const head = <>
    <TableCell>Email</TableCell>
    <TableCell sx={{display: matchesLgDown ? 'none' : 'table-cell'}}>Created at (UTC)</TableCell>
    <TableCell sx={{display: matchesMdDown ? 'none' : 'table-cell'}}>User ID</TableCell>
    <TableCell sx={{width: 0}}></TableCell>
  </>

  return (
    <Box maxWidth={Width.WIDE}>
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 1}}>
        <Typography variant="h4">Users</Typography>
        <Box sx={{display: {xs: 'flex', md: 'none'}, flexDirection: 'row', gap: 1}}>
          <Button variant="contained" onClick={() => navigate("import")}
                  sx={{display: {xs: 'inline-flex', md: 'none'}, minWidth: '54px'}} size="medium"><Upload/></Button>
          <Button variant="contained" onClick={() => setCreateUserModalOpen(true)}
                  sx={{display: {xs: 'inline-flex', md: 'none'}, minWidth: '54px'}} size="medium"><PersonAdd/></Button>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        mt: 4,
        mb: 5,
        gap: 2
      }}>
        <form onSubmit={search} style={{width: '100%'}}>
          <Box display="flex" flexDirection="row" gap="8px">
            <TextField hiddenLabel size="small" name="search" variant="outlined" placeholder="Search users"
                       defaultValue={searchParams.get("q")} sx={{
              maxWidth: '400px',
              width: '100%'
            }}/>
            <Button type="submit" variant="contained" startIcon={<Search/>} sx={{minWidth: 'fit-content'}}>
              Search
            </Button>
          </Box>
        </form>
        <Box sx={{display: {xs: 'none', md: 'flex'}, flexDirection: 'row', gap: 1}}>
          <Button variant="contained" startIcon={<Upload/>} onClick={() => navigate("import")}
                  sx={{minWidth: 'fit-content'}}>Import</Button>
          <Button variant="contained" startIcon={<PersonAdd/>} onClick={() => setCreateUserModalOpen(true)}
                  sx={{whiteSpace: 'nowrap', minWidth: 'fit-content'}}>Create new</Button>
        </Box>
      </Box>
      {project.status.replicas === 0 ?
        <SuspendedInfoComponent organization={organization} project={project} type="list"/> : null}
      {loader !== undefined && project.status.replicas > 0 ?
        <React.Suspense
          fallback={<LoadingTable size="small"
                                  columns={columnCount}
                                  rowsPerPage={parseInt(searchParams.get("per_page") ?? "25", 10)}
                                  onRowsPerPageChange={onRowsPerPageChange}>
            {head}
          </LoadingTable>}>
          <Await resolve={loader}
                 errorElement={
                   <ErrorTable size="small"
                               message="Failed to load user."
                               columns={columnCount}
                               rowsPerPage={parseInt(searchParams.get("per_page") ?? "25", 10)}
                               onRowsPerPageChange={onRowsPerPageChange}>{head}</ErrorTable>
                 }>
            {(users: Awaited<ProjectUsersWithTotalCount>) => (
              <>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {head}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.user.length !== 0 ? null : <TableRow>
                        <TableCell colSpan={4} sx={{color: '#7C8E9C'}}
                                   size="medium">{(searchParams.get("q") ?? "") === "" ? "This project has no users yet." : "No results found"}</TableCell>
                      </TableRow>}
                      {users.user.map((user) => {
                        const createdAt = new Intl.DateTimeFormat('default', {
                          dateStyle: "medium",
                          timeStyle: "medium"
                        }).format(new Date(user.created_at))
                        return <TableRow hover key={user.id} onClick={() => navigate(user.id)} sx={{cursor: 'pointer'}}>
                          <TableCell>{user.emails?.find((value) => value.is_primary)?.address ?? ""}</TableCell>
                          <TableCell
                            sx={{display: matchesLgDown ? 'none' : 'table-cell'}}>{createdAt}</TableCell>
                          <TableCell sx={{display: matchesMdDown ? 'none' : 'table-cell'}}>{user.id}</TableCell>
                          <TableCell sx={{verticalAlign: 'bottom'}}>
                            <IconButton aria-label="navigate"
                                        size="small" disableRipple>
                              <ChevronRight fontSize="medium"/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={users.totalCount}
                          page={parseInt(searchParams.get("page") ?? "0", 10)}
                          rowsPerPage={parseInt(searchParams.get("per_page") ?? "25", 10)}
                          onRowsPerPageChange={onRowsPerPageChange}
                          onPageChange={onPageChange}
                          showFirstButton
                          showLastButton
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </>
            )}
          </Await>
        </React.Suspense>
        : null
      }
      <CreateUserModal organizationId={organization.id} projectId={project.id} open={isCreateUserModalOpen}
                       onClose={onCloseCreateUserModal}/>
    </Box>
  )
}

export default User
