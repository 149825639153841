import { register } from "@teamhanko/hanko-elements"
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import logo from '../assets/hanko-logo.svg'
import { CheckCircleOutlined } from "@mui/icons-material";
import discordIcon from '../assets/discord.svg'
import iconBackground from '../assets/icon_background.svg'

export const Login = () => {
  const navigate = useNavigate()
  const {state} = useLocation()

  const redirectAfterLogin = useCallback(() => {
    if (state) {
      navigate((state as string), {replace: true})
    } else {
      navigate("/organizations/", {replace: true})
    }
  }, [navigate, state])

  useEffect(() => {
    document.addEventListener("hankoAuthSuccess", redirectAfterLogin)
    return () => {
      document.removeEventListener("hankoAuthSuccess", redirectAfterLogin)
    }
  }, [redirectAfterLogin])

  useEffect(() => {
    register(window.REACT_APP_LOGIN_PROVIDER_URL, {shadow: false, injectStyles: true})
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return <>
    <Box sx={{position: 'absolute', m: 3, top: 0, left: 0}}>
      <img src={logo} alt="Hanko logo" style={{width: '100%', maxWidth: '150px', height: '50px'}}/>
    </Box>
    <Box sx={{
      height: {xs: 'unset', lg: '100vh'},
      maxWidth: {xs: '100%', lg: '1100px'},
      minWidth: {xs: '0px', lg: '960px'},
      display: 'flex',
      flexDirection: {xs: 'column-reverse', lg: 'row'},
      justifyContent: {xs: 'center', lg: 'space-between'},
      margin: '0 auto',
      overflow: 'auto',
      minHeight: '886px',
    }}>
      <Box sx={{
        display: 'flex',
        height: {xs: '40%', lg: '100vh'},
        maxWidth: {xs: '100vw', lg: '460px'},
        justifyContent: 'center',
        alignContent: {xs: 'start', lg: 'center'},
        flexWrap: 'wrap',
        minHeight: '150px',
        padding: {
          xs: '0 0 16px 0',
          lg: 0,
        }
      }}>
        <Box sx={{
          maxWidth: '460px',
          height: 'fit-content',
          display: {
            xs: 'none',
            lg: 'flex'
          },
          flexDirection: 'column',
          gap: 4
        }}>
          <ListItem title="Free 10,000 monthly active users"
                    body="Start for free with passkeys, social logins, email passcodes, optional passwords and user management."/>
          <ListItem title="Integrate auth and passkeys in minutes"
                    body="Solve auth for your new project with Hanko’s customizable login and user profile web components or integrate passkeys into your existing user base with Passkey API."/>
          <ListItem title="Join industry leaders that use Hanko for their login"
                    body="Cal.com, Volt.io, mementor, and many more."/>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', mt: 12, gap: 3, mx: {xs: 3, lg: 0}}}>
          <Link href="https://www.hanko.io/community" sx={{display: 'block', width: 'fit-content', margin: '0 auto'}}>
            <Box sx={{display: 'block', position: 'relative', height: '40px', width: '40px'}}>
              <img src={iconBackground} alt="Discord background" style={{position: 'absolute'}}/>
              <img src={discordIcon} alt="Discord community" style={{position: 'absolute', padding: '10px 7px'}}/>
            </Box>
          </Link>
          <Box sx={{
            padding: '16px 24px',
            border: '1px solid #262B35',
            borderRadius: 2,
            maxWidth: '460px'
          }}>
            Not sure if Hanko offers what you need? Join our friendly <Link href="https://www.hanko.io/community"
                                                                         sx={{color: '#5865F2', fontWeight: 500}}>Discord
            community</Link> and ask any questions there or fill out our <Link href="https://www.hanko.io/sales"
                                                                               sx={{color: '#5865F2', fontWeight: 500}}>contact
            form</Link> and we'll get back to you.
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        height: {xs: '60%', lg: '100vh'},
        maxWidth: {xs: '100vw', lg: '460px'},
        justifyContent: 'center',
        alignContent: {xs: 'end', lg: 'center'},
        flexWrap: 'wrap',
        minHeight: '524px',
        backgroundColor: {xs: '#262B35', lg: 'unset'},
        padding: {
          xs: '104px 0 0 0',
          lg: 0,
        }
      }}>
        <Box sx={{borderRadius: 2, backgroundColor: '#262B35', overflow: 'hidden', paddingY: 6, maxWidth: '460px'}}>
          <hanko-auth lang="en" experimental="conditionalMediation"/>
          <Box sx={{mx: 6.875, mt: 0}} width="fit-content">
            <Typography component="p" variant="caption" sx={{color: '#A8AAAE', fontSize: '0.625rem'}}>By creating an
              account, you agree to our <Link color="inherit" href="https://www.hanko.io/terms"
                                              target="_blank">terms</Link> and <Link color="inherit"
                                                                                     href="https://www.hanko.io/privacy"
                                                                                     target="_blank">privacy
                policy</Link>.</Typography>
            <Box sx={{mt: 1}}>
              <Typography component="p" variant="caption"
                          sx={{color: '#A8AAAe', fontSize: '0.625rem', lineHeight: '154%'}}>
                After setting up your account, we will send you a welcome email and subscribe you to our monthly update
                with product and industry news, which you can unsubscribe from at any time.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </>
}

const ListItem = (props: { title: string, body: string }) => {
  return <Box>
    <Box sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
      <Box>
        <CheckCircleOutlined color="success"/>
      </Box>
      <Box>
        <Typography component="p" sx={{fontSize: '15px', fontWeight: 700}}>{props.title}</Typography>
        <Typography component="p" variant="body1">{props.body}</Typography>
      </Box>
    </Box>
  </Box>
}
