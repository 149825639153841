import { WebAuthnCredential } from "../../models/ProjectUser";
import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

export const WebAuthnCredentialTable = (props: { webauthCredentials: WebAuthnCredential[] }) => {

  const getBackupState = (credential: WebAuthnCredential) => {
    if (credential.backup_state) {
      return <Chip label="Backed up" color="success" sx={{lineHeight: 1.5}}/>
    } else if (credential.backup_eligible) {
      return <Chip label="Eligible" color="primary" sx={{lineHeight: 1.5}}/>
    } else {
      return <Chip label="Device-bound" sx={{lineHeight: 1.5}}/>
    }
  }

  const dateTimeFormatter = new Intl.DateTimeFormat('default', {
    dateStyle: "medium",
    timeStyle: "medium"
  })

  const uiDisplayName = (credential: WebAuthnCredential) => {
    if (credential.name) {
      return credential.name;
    }
    const alphanumeric = credential.public_key.replace(/[\W_]/g, "");
    return `Passkey-${alphanumeric.substring(
      alphanumeric.length - 7,
      alphanumeric.length
    )}`;
  };

  return <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Created at (UTC)</TableCell>
        <TableCell>Backup state</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {props.webauthCredentials.length === 0 ?
        <TableRow><TableCell colSpan={3} sx={{color: "#7C8E9C"}} size="medium">No Credentials</TableCell></TableRow> : null}
      {props.webauthCredentials.map((cred) => {
        return <TableRow key={cred.id}>
          <TableCell>{uiDisplayName(cred)}</TableCell>
          <TableCell>{dateTimeFormatter.format(new Date(cred.created_at))}</TableCell>
          <TableCell>{getBackupState(cred)}</TableCell>
        </TableRow>
      })}
    </TableBody>
  </Table>
}